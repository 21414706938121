/* FooterBanner.css */
.banner {
    background-color: #5e49bb;
    color: white;
    text-align: center;
    padding: 20px;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 1000; /* Ensure it stays on top */
}
.banner a {
    color: white;
    text-decoration: none;
    font-weight: bold;
}
